import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
// import Contracts from "./pages/contracts/index";
// import Deals from "./pages/deals/create";
import config from "./config";
import Deals from "./pages/deals/index";
import Contracts from "./pages/contracts/index";
import Deal from "./pages/deals/deal";

export default function Root(props) {

  console.log("deal permission props " , props)

  let context = { ...props };

  const Pusher = require("pusher-js");

  var pusherConnection = new Pusher(config.pusherClientId, {
    cluster: config.pusherRegion
  });


  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access["client-contracts"]["A"] &&
                      //props.auth.access["documents"]["R"] && 
                      (
                        <Route
                          path="/client-contracts/index"
                          exact
                          component={(route) => (
                            <Contracts
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["client-deals"]["A"] &&
                      //props.auth.access["documents"]["R"] && 
                      (
                        <Route
                          path="/client-deals/index"
                          exact
                          component={(route) => (
                            <Deals
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["client-deals"]["A"] &&
                      //props.auth.access["documents"]["R"] && 
                      (
                        <Route
                          path="/client-deals/deal/:id"
                          exact
                          component={(route) => (
                            <Deal
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
{/* 
                    {props.auth.access["submit-ticket"]["A"] &&
                      //props.auth.access["deal-builder"]["C"] && 
                      (
                        <Route
                          path="/submit-ticket/create"
                          exact
                          component={(route) => (
                            <TicketsCreate
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["submit-ticket"]["A"] &&
                      //props.auth.access["deal-builder"]["C"] && 
                      (
                        <Route
                          path="/submit-ticket/edit/:id"
                          exact
                          component={(route) => (
                            <TicketsEdit
                              route={route}
                              context={context}
                              pusherConnection={pusherConnection}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["client-details"]["A"] &&
                      //props.auth.access["deal-builder"]["C"] && 
                      (
                        <Route
                          path="/client-details/index"
                          exact
                          component={(route) => (
                            <ClientDetails
                              route={route}
                              context={context}
                              pusherConnection={pusherConnection}
                            />
                          )}
                        />
                      )} */}

                    {/* {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/view/:id"
                          exact
                          component={(route) => (
                            <DealView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["C"] && (
                        <Route
                          path="/apps/create"
                          exact
                          component={(route) => (
                            <DealCreate
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["U"] && (
                        <Route
                          path="/apps/edit/:id"
                          exact
                          component={(route) => (
                            <DealEdit
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                      {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/product/:id"
                          exact
                          component={(route) => (
                            <ProductPage
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                     

                      {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/index"
                          exact
                          component={(route) => (
                            <DealsConfigIndex
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/view/:id"
                          exact
                          component={(route) => (
                            <ConfigView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/product/:id"
                          exact
                          component={(route) => (
                            <ConfigProduct
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )} */}

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
