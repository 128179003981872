import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";

import { NavLink } from "react-router-dom";
import "./dealStyle.css";
import { buttonStylePrimary } from "./styles";

import PageviewIcon from '@mui/icons-material/Pageview';

import { PortalApi } from "@unity/components";


export default function Deal({deal, viewMode}) {

    const [loading, setLoading] = useState(false);
    const [imgLink, setImgLink] = useState(false);

    const getImgLink = async (docId) => {
        try {
            const res = await PortalApi.getLink(docId);
            if(res?.data == null || res?.data == undefined) throw "Call Error";
            setImgLink(res.data);
        } catch (error) {
            alert(`Failed to Fetch Deal Image ${error}`);
        }
    }

    useEffect(() => {
        if(deal?.display_id !== undefined && deal?.display_id !== null) getImgLink(deal.display_id);
    }, []);

    return (
        <div className={viewMode ? "deal-box-list" : "deal-box"}>
            <Typography variant="h5">
                {deal.name}
            </Typography>
            {
                <img 
                    className="deal-image"
                    width="100"
                    height="100"
                    src={deal.display_id == null || !imgLink ? require('../../common/assets/placeholderUnity.png') : imgLink}
                />
            }
            <Typography>
                <i>{deal.ref}</i>
            </Typography>
            <NavLink to={`/client-deals/deal/${deal.id}`}>
                <Button
                    style={ buttonStylePrimary }
                    variant="contained"
                    color="primary"
                    startIcon={<PageviewIcon/>}
                >
                    View Deal
                </Button>
            </NavLink>
            <Typography>Valid: <br/> {new Date(deal.valid_from).toLocaleDateString()} - {new Date(deal.valid_to).toLocaleDateString()}</Typography>
        </div>
    );
} 