import React, { useEffect, useState } from "react";

import { Badge, Button, CircularProgress, FormControlLabel, Grid, IconButton, LinearProgress, Switch, Typography } from "@mui/material";

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Basket from "./basket";
import Deal from "../../modules/deals/deal"

import { DealBuilderApi } from "@unity/components";

import "./dealStyle.css";

const verticalStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2vh"
};


export default function Deals({context, location}) {

  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(1);
  const [total, setTotal] = useState(0);
  const [deals, setDeals] = useState([]);
  const [basketOpen, setBasketOpen] = useState(false);
  const [basket, setBasket] = useState({products: []});
  const [loading, setLoading] = useState(false);

  const [viewMode, setViewMode] = useState(false);

  const fetchDeals = async (newPage = page) => {
      setLoading(true);
      try {
          const res = await DealBuilderApi.getDeals(newPage);
          if(!res?.success || res?.data == null ||res?.data == undefined) throw "Call Failed";
          setDeals(res.data);
          setPage(res.meta.current_page);
          setPageMax(res.meta.last_page);
          setTotal(res.meta.total);
      } catch (error) {
          alert(`Failed to Load Deals - ${error}`);
      }
      setLoading(false);
  };

  const fetchBasket = async () => {
      setLoading(true);
      try {
          const res = await DealBuilderApi.basket();
          if(!res?.success || res?.data == null ||res?.data == undefined) throw "Call Failed";
          setBasket(res.data);
      } catch (error) {
          alert(`Failed to Load Basket - ${error}`);
      }
      setLoading(false);
  }

  useEffect(() => {
      fetchDeals();
      fetchBasket();

      if(window.localStorage.getItem("deals-view") !== null) setViewMode(Number(window.localStorage.getItem("deals-view")));
  }, []);

  if(loading) return (
      <div style={verticalStyle}>
          <CircularProgress/>
          <Typography>
              Loading Deals...
          </Typography>
      </div>
  )

  return (
      <div className="deal-container">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", width: "100%", borderBottom: "2px solid black" }}>
              <Typography variant="h4" className="deal-section-header">Deals</Typography>
              <Button style={{ color: "green"}} startIcon={
                  <Badge badgeContent={basket !== undefined && basket?.products !== undefined ? basket.products.length : null} color="error" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                      <ShoppingBasketIcon/>
                  </Badge>
              } 
                  onClick={(e) => {setBasketOpen(e.currentTarget)}}
              >
                  Basket
              </Button>
              <Basket basket={basket} setOpen={setBasketOpen} open={basketOpen} setBasket={setBasket} context={context}/>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <FormControlLabel
                  label={ viewMode ? "List View" : "Table View" }
                  control={
                      <Switch
                          checked={Boolean(viewMode)}
                          onChange={(e, checked) => {setViewMode(checked); window.localStorage.setItem("deals-view", Number(checked));}}
                      />
                  }
              />
          </div>
          <div className={viewMode ? "deal-list" : "deal-list-grid"}>
              {
                  deals.map((deal, index) => (<Deal deal={deal} key={index} viewMode={viewMode}/>))
              }
          </div>
              
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <IconButton
                  onClick={() => {fetchDeals(page - 1)}}
                  disabled={page - 1 <= 0}
              >
                  <ArrowBackIcon/>
              </IconButton>
              <div style={{ width: "80vw" }}>
                  <LinearProgress
                      value={(page / pageMax) * 100}
                      variant="determinate"
                  />
              </div>
              <IconButton
                  onClick={() => {fetchDeals(page + 1)}}
                  disabled={page + 1 > pageMax}
              >
                  <ArrowForwardIcon/>
              </IconButton>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: "0px" }}>
              <Typography>{page} / {pageMax}</Typography>
          </div>
      </div>
  );
}
