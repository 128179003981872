import React, { useState, useEffect } from "react";

import { Typography, Button } from "@mui/material";

import PageviewIcon from '@mui/icons-material/Pageview';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import ProductDetails from "./productDetails";
import { DealBuilderApi , PortalApi , CodesApi } from "@unity/components";
import "./dealStyle.css";
import { buttonStylePrimary, buttonStyleConfirm } from "./styles";

export default function Product({product, addToBasket, viewMode}) {
    const [imgLink, setImgLink] = useState(false);
    const [productOpen, setProductOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const getImgLink = async (docId) => {
        try {
            const res = await PortalApi.getLink(docId);
            if(res?.data == null || res?.data == undefined) throw "Call Error";
            setImgLink(res.data);
        } catch (error) {
            alert(`Failed to Fetch Deal Image ${error}`);
        }
    }

    useEffect(() => {
        if(product?.display_id !== undefined && product?.display_id !== null) 
            getImgLink(product.display_id)
    })

    return (
        <div className={viewMode ? "deal-box-list" : "deal-box"}>
            <Typography variant="h5">
                {product.name}
            </Typography>
            {
                <img 
                    className="deal-image"
                    width="100"
                    height="100"
                    src={product.display_id == null || !imgLink ? require('../../common/assets/placeholderUnity.png') : imgLink}
                />
            }
            <Typography>
                <i>{product.product_ref}</i>
            </Typography>
            <Button
                style={ buttonStylePrimary }
                variant="contained"
                color="primary"
                startIcon={<PageviewIcon/>}
                onClick={() => {setSelectedProduct(product.id); setProductOpen(true);}}
            >
                View Product
            </Button>
            <ProductDetails productId={product.id} open={productOpen} setOpen={setProductOpen} addToBasket={addToBasket} />
        </div>
    )
}