import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from  "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';

import { DealBuilderApi , PortalApi , CodesApi } from "@unity/components";

import { buttonStyleConfirm } from "./styles";

import "./productDetailStyle.css";


const verticalStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

const calculateTotal = (lines, product, setter = false) => {
    if(lines?.length == null || lines?.length == undefined) return;
    const tempProduct = {...product};
    let total = 0;

    for(let i = 0; i < lines.length; i++) {
        if(lines[i] == undefined) continue;
        const line = lines[i];
        if((line?.dynamic == undefined || line?.dynamic == null || !line?.dynamic)) {
            let qty = line.quantity !== null ? Number(line.quantity) : 0;
            let subTotal = line.unit_price !== null ? Number(line.unit_price) : 0;
            total += (qty * subTotal);
            continue;
        }
        if(line?.options == null || line?.options == undefined) continue;

        let set = false;
        for(let j = 0; j < line.options.length; j++) {
            if(line.options[j] == undefined || set) continue;

            const detail = line.options[j];
            if(detail?.in_basket == undefined || detail?.in_basket == null || !detail?.in_basket) continue;
            let qty = line.quantity !== null ? Number(line.quantity) : 0;
            let subTotal = detail.unit_price !== null ? Number(detail.unit_price) : 0;
            total += (qty * subTotal);
            set = true;
        }

        if(!set) {total = false; break;} // User has a dynamic option that has not had a selection - break out
    }

    tempProduct.cost_total = total;
    if(setter) return setter(tempProduct);
    return tempProduct.cost_total;
}

export default function ProductDetails({productId, open, setOpen, addToBasket}) {
    const [product, setProduct] = useState(false);
    const [pcs, setPcs] = useState([]);
    const [lines, setLines] = useState(false);
    const [imgLink, setImgLink] = useState(false);
    const [loading, setLoading] = useState(false);

    const getImgLink = async (prodId) => {
        try {
            const res = await PortalApi.getLink(prodId);
            if(res?.data == null || res?.data == undefined) throw "Call Failed";
            setImgLink(res.data);
        } catch (error) {
            alert("Failed to load image")
        }
    }



    const fetchProduct = async () => {
        setLoading(true);
        try {
            const res = await DealBuilderApi.getProduct(productId , {productId: productId});
            if(!res.success || res?.data == null | res?.data == undefined) throw "Call Failed";
            setProduct(res.data);
            if(res.data.display_id !== undefined && res.data.display_id !== null) getImgLink(res.data.display_id);
            setLines(res.data.lines);
            calculateTotal(lines, product, setProduct);
        } catch (error) {
            alert(`Failed to load product ${error}`);
        }
        setLoading(false);
    }

    const fetchPcs = async () => {
        setLoading(true);
        try {
            const res = await CodesApi.getPeriodicCodes();
            if(!res.success || res.data == undefined || res.data == null) throw "Call Failed";
            setPcs(res.data);
        } catch (error) {
            alert(`Failed to Load Periodic Codes - ${error}`);
        }
        setLoading(false);
    }



    const handleOptionSelect = async (lineIndex, optionIndex) => {
        const tempLines  = [...lines];
        const tempLine   = {...tempLines[lineIndex]};
        const tempOption = {...tempLine.options[optionIndex]};

        for(let i = 0; i < tempLine.options.length; i++) {
            const tempO = {... tempLine.options[i]};
            tempO.in_basket = false;
            tempLine.options[i] = tempO;   
        }

        tempOption.in_basket = true;
        tempLine.options[optionIndex] = tempOption;
        tempLine.price = tempOption.material_cost;
        tempLine.material_ref = tempOption.material_ref;
        tempLine.min_term = tempOption.minimum_term;
        tempLine.periodic_code_id = tempOption.periodic_code_id;
        tempLines[lineIndex] = tempLine;
        await setLines(tempLines);
        calculateTotal(lines, product, setProduct);
    }

    const findPeriodicName = (codeId) => {
        const results = pcs.filter((item) => codeId == item.id);
        if(results.length <= 0) return "NA";
        return results[0].name;
    }

    const LineRow = ({line, lineIndex}) => (
        <>
            <TableRow style={{ backgroundColor: lineIndex % 2 == 0 ? "aliceblue" : "white", width: "100%" }}>
                <TableCell>
                    {line.description} {(line.quantity !== null || line.quantity != 0 || line.quantity != 1) ? `(${line.quantity})` : null }
                </TableCell>
                <TableCell>
                    {line?.unit_price !== null ? line.unit_price : "NA"}
                </TableCell>
                {/* <TableCell>
                    {line.material_ref !== null ? line.material_ref : "NA"}
                </TableCell> */}
                <TableCell>
                    {line?.minimum_term !== null ? line.minimum_term : "NA"}
                </TableCell>
            </TableRow>
                {
                    (line.options !== undefined && line.options !== null && line.options.length > 0) && (
                        <TableRow style={{ backgroundColor: lineIndex % 2 == 0 ? "aliceblue" : "white" }}>
                        <TableCell colSpan={4} align="center">
                            <div style={{ width: "60vw", overflowX: "scroll" }}>
                                <ButtonGroup style={{ overflowX: "hidden", marginBottom: "1vh" }}>
                                    {
                                        line.options.map((option, optionIndex) => (
                                            <Button
                                                key={optionIndex}
                                                size="small"
                                                onClick={() => {handleOptionSelect(lineIndex, optionIndex)}}
                                                style={{ backgroundColor: option.in_basket ? "green" : null, color: option.in_basket ? "white" : null }}
                                            >
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1vh" }}>
                                                    <Typography>{option.material_name}</Typography>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1vw" }}>
                                                        <Typography><LocalOfferIcon/> {option.unit_price !== null ? `£${option.unit_price}` : "None"}</Typography>
                                                        <Typography><CalendarTodayIcon/> {option.periodic_code_id !== null ? findPeriodicName(option.periodic_code_id) : "immediate"}</Typography>
                                                        <Typography><EventIcon/> {option.min_term !== null && option.min_term !== undefined ? option.min_term : "None"}</Typography>
                                                    </div>
                                                </div>
                                            </Button>            
                                        ))
                                    }
                                </ButtonGroup>
                            </div>
                        </TableCell>
                        </TableRow>
                    )
                }
        </>
    )

    useEffect(() => {
        fetchProduct();
        fetchPcs();        
    }, []);

    useEffect(() => {
        calculateTotal(lines, product, setProduct);
    }, [open])

    if(!product || loading) return (
        <div style={verticalStyle}>
            <CircularProgress/>
            <Typography>Loading Product Details...</Typography>
        </div>
    )

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={() => {setOpen(false)}}
        >
            <DialogTitle style={{ backgroundColor: "rgb(77, 208, 225)", color: "white" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h5">{product.name}</Typography>
                    <IconButton
                        onClick={() => {setOpen(false)}}
                        style={{ color: "white" }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" gutterBottom><i>{product.product_ref}</i></Typography>
                    <Typography variant="h6" gutterBottom>Availability: {
                        product.active ? (
                            <span style={{ color: "green" }}>In Stock</span>
                        ) : (
                            <span style={{ color: "red" }}>Out of Stock</span>
                        )
                    }</Typography>
                </div>
                <div className="product-contents">
                    <img
                        src={!imgLink ? require("../../common/assets/placeholderUnity.png") : imgLink}
                        width="300px"
                        height="300px"
                        className="main-image"
                    />
                    <div className="product-details">
                        <Typography gutterBottom>{product.description}</Typography>
                        <Typography gutterBottom><b>Valid Till: </b>{ 
                            new Date(product.valid_to).toLocaleDateString()
                        }</Typography>
                        <Typography>
                            <b>Initial Price:</b> {
                            product.cost_total ? `£${product.cost_total}` : "Please ensure all optional choices have been made"
                            }
                        </Typography>
                    </div>
                </div>
            <TableContainer style={{ width: "100%" }}>
                <Table size="small">
                    <TableHead style={{ 
                        backgroundColor: "rgb(77, 208, 225)"
                    }}>
                        <TableRow>
                            <TableCell><Typography className="detail-header">Detail Name (Quantity):</Typography></TableCell>
                            <TableCell><Typography className="detail-header">Detail Cost:</Typography></TableCell>
                            {/* <TableCell><Typography className="detail-header">Detail Ref:</Typography></TableCell> */}
                            <TableCell><Typography className="detail-header">Detail Min Term:</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !lines || lines?.length <= 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        No Details For This Product
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                {
                                    lines.map((line, lineIndex) => (
                                        <LineRow line={line} lineIndex={lineIndex} key={lineIndex} />
                                    ))
                                }
                                </>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                style={ buttonStyleConfirm }
                variant="contained"
                color="primary"
                startIcon={<AddShoppingCartIcon/>}
                disabled={product.cost_total == undefined && !product.cost_total}
                onClick={() => {addToBasket(product.id, product)}}
                fullWidth
            >
                Add to Cart
            </Button>
            </DialogContent>
        </Dialog>
    );
}

export { calculateTotal }